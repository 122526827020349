<template>
  <PreviewSpiner v-if="!ready"/>
  <div class="create-page" v-else>
    <CForm
      v-on:submit.prevent="createSingleStep"
      novalidate
      class="create-page__form"
    >
      <FormBuilder
        :list="formList"
        @updated="updateSettingsData"
        :page="{ title: texts.createPage.title, info: descriptions }"
      />
    </CForm>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import fieldHelpers from '@/utils/ZiqniFieldHelper';
import {customFields} from '@/config/descriptions/customFields.json';
import {customFieldsTexts} from '@/config/pageTexts/customFields.json';
import customFieldFields from '@/generated/ziqni/store/modules/customFields/fields';
import { delay } from 'lodash';
import PreviewSpiner from "@/shared/UI/Spiner";


export default {
  name: 'CreateCustomField',
  components: {
    PreviewSpiner,
  },
  data() {
    return {
      model: 'customFields',
      ready: true,
      descriptions: {
        ...customFields.create,
      },
      texts: {
        ...customFieldsTexts,
      },
      formList: [],
      settingsData: {},
      translationsData: {},
      translationsMap: {},
      requiredFields: [],
      translatableFields: [],
    };
  },
  computed: {
    ...mapGetters('customFields', ['message', 'loading']),
    isButtonDisabled() {
      if (this.loading) return true;
      return !!this.message;
    },
  },
  provide() {
    return {
      stepKeys: this.stepKeys,
      model: this.model,
    }
  },
  watch: {
    message(val) {
      if (val === this.texts.createPage.duplicateMessage) {
        this.idValidate = false;
        this.invalidRefIdFeedback = null;
      } else if (val === this.texts.createPage.emptyMessage) {
        this.idValidate = false;
        this.invalidRefIdFeedback = null;
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions(
      'customFields',
      [
        'handleCreateCustomFields',
      ]
    ),
    initialize() {
      routerBreadcrumbs(this.$router.currentRoute);

      this.formList = fieldHelpers.prepareCreateFormList(
        customFieldFields,
        customFieldsTexts.createPage,
        customFields.create
      );

      this.formList.unshift({
        "key": "published",
        "label": "Public",
        "tooltip": this.descriptions.public,
        "type": "SWITCH",
        "model": "CreateFileRepositoryRequest",
        "value": false,
        "disabled": false,
        "options": null,
        "multiple": false,
        "required": true,
        "showKey": false
      });

      this.formList.forEach(field => {
        if (field.required) {
          this.requiredFields.push(field.key);
          if (field.type === 'NAMEKEY') {
            this.requiredFields.push('name');
          }
        }
      })
    },
    updateCurrentStep(val) {
      this.currentStep = val;
    },
    nextStep() {
      let invalidFields = this.getInvalidFields(true);
      if (!invalidFields.length) {
        this.currentStep += 1;
      } else {
        this.setInvalidFields(invalidFields);
      }
    },
    getInvalidFields() {
      let result = [];
      //TODO: remove after implementing constraint
      this.settingsData.constraints = [];

      for (let key in this.settingsData) {
        if (this.requiredFields.includes(key) && (this.settingsData[key] === null || this.settingsData[key] === '')) {
          result.push(key);
        }
      }

      return result;
    },
    updateSettingsData(val) {
      this.settingsData = val;
    },
    setInvalidFields(invalidFields) {
      invalidFields.forEach(invalidField => {
        let invalidFieldElement = document.getElementsByName(invalidField)[0];
        let invalidElement = invalidFieldElement;
        if (!invalidFieldElement.classList.contains('zq--form-row')) {
          invalidElement = invalidFieldElement.parentNode;
        }
        invalidElement.classList.add('zq-invalid');
      })
    },
    createSingleStep() {
      let invalidFields = this.getInvalidFields();
      if (!invalidFields.length) {
        this.createEntity();
      } else {
        this.setInvalidFields(invalidFields)
      }
    },
    createEntity() {
      let formData = {};

      if (Object.keys(this.settingsData).length) {
        delete this.settingsData.constraints;
        formData = {...this.settingsData};
      }

      if (formData.key && typeof formData.key === 'object') {
        formData.name = formData.key.name;
        formData.key = formData.key.key;
      }

      if (formData.published) {
        if (!formData.addConstraints || !Array.isArray(formData.addConstraints)) {
          formData.addConstraints = [];
        }
        formData.addConstraints.push('public')
      }
      delete formData.published;

      const body = [];
      body.push(JSON.parse(JSON.stringify(formData)));

      this.handleCreateCustomFields({createCustomFieldRequestArray: body})
        .then(data => {
          if (data.length) {
            this.ready = false;
            delay(() => {
              this.$router.push({
                name: 'PreviewCustomField',
                params: {
                  id: data[0].id,
                }
              })
            }, 1500);
          } else {
            console.log('Something went wrong');
          }
        });
    },
  },
};
</script>

<style lang="scss">
.create-page {
  &__header {
    background-color: var(--zq-main-bg);
  }

  .zq-invalid {
    .form-control {
      border: solid 1px var(--zq-warn);
    }
  }
}
</style>
